import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { MediaImage } from './MediaImage';
import * as MediaUrls from '../mediaApi/MediaUrlBuilder';

export type Props = {
    title: string;
    description: string;
    linkTitle: string;
    linkUrl: string;
    backgroundMediaId: string;
};

export default function PartnershipHeroMarketingTile({
    backgroundMediaId,
    description,
    linkTitle,
    linkUrl,
    title,
}: Props) {
    return (
        <div className="relative h-full">
            {backgroundMediaId && (
                <MediaImage
                    key={backgroundMediaId}
                    className="h-full w-full object-cover"
                    alt="Hero Image Background"
                    urlFunc={() => MediaUrls.globalHeroDownload(backgroundMediaId)}
                />
            )}

            <div className="absolute inset-0 tracking-tighter">
                <div className="mx-[72px] mt-14 h-[411px] w-[586px] bg-white pb-12 pl-14 pr-[48px] pt-[52px]">
                    <h2 className="mb-9">{title}</h2>

                    <div
                        className="mb-6 h-[172px] overflow-y-auto text-base font-normal"
                        dangerouslySetInnerHTML={{ __html: description }}
                    />

                    <a
                        href={linkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex w-fit space-x-2 bg-secondary-500 px-5 py-2.5 text-white"
                    >
                        <div className="text-sm font-medium uppercase">{linkTitle}</div>

                        <ArrowLongRightIcon className="h-5 w-5" aria-hidden="true" />
                    </a>
                </div>
            </div>
        </div>
    );
}
