import { HeaderIndicatorType } from '../consts/enums';

export type Props = {
    type: HeaderIndicatorType;
};

const HEADER_INDICATOR_STYLES = {
    [HeaderIndicatorType.Draft]: {
        iconColor: 'bg-primary-500',
        backgroundColor: 'bg-background',
        content: 'Draft Preview',
    },
    [HeaderIndicatorType.Published]: {
        iconColor: 'bg-green-500',
        backgroundColor: 'bg-green-50',
        content: 'Published Content',
    },
};

export default function HeaderIndicator({ type }: Props) {
    const { iconColor, backgroundColor, content } = HEADER_INDICATOR_STYLES[type];

    return (
        <div className={`flex w-fit items-center space-x-2 rounded-full px-2.5 py-0.5 ${backgroundColor}`}>
            <div className={`h-3 w-3 rounded-full ${iconColor}`} />
            <div className="disclaimer uppercase">{content}</div>
        </div>
    );
}
