import { MediaImage } from '../../common/components/MediaImage';
import * as MediaUrls from '../../common/mediaApi/MediaUrlBuilder';
import { buttonStyleClass } from '../../common/ButtonNew';

export type Props = {
    title: string;
    description: string;
    linkTitle: string;
    linkUrl: string;
    tileMediaId: string;
};

export default function AudienceMarketingMaterialTile({ title, description, linkTitle, linkUrl, tileMediaId }: Props) {
    return (
        <div className="mx-3 flex h-[380px] rounded-sm">
            <div className="w-[466px] flex-initial bg-black px-12 py-[60px] text-white">
                <h3 className="mb-4 leading-[120%]">{title}</h3>
                <div className="mb-6 leading-[130%]" dangerouslySetInnerHTML={{ __html: description }} />
                <a href={linkUrl} target="_blank" rel="noopener noreferrer" className={buttonStyleClass({})}>
                    {linkTitle}
                </a>
            </div>
            {tileMediaId && (
                <div className="pointer-events-none flex-1">
                    <MediaImage
                        key={tileMediaId}
                        className="ml-[-1px] h-full w-full min-w-0 object-cover"
                        alt="Carousel Image Background"
                        urlFunc={() => MediaUrls.globalTileDownload(tileMediaId)}
                    />
                </div>
            )}
        </div>
    );
}
