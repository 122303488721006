import React, { ForwardedRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import * as FormStyles from '../../styles/FormStyles';
import { onChangeEvent, onFocusEvent, onBlurEvent } from './LabeledInput';

type RichTextEditorProps = {
    name: string;
    value: string | null | undefined;
    className?: string;
    readOnly?: boolean;
    hasError?: boolean;
    onChange?: onChangeEvent;
    onFocus?: onFocusEvent;
    onBlur?: onBlurEvent;
};

const Link = Quill.import('formats/link');
class AbsoluteLink extends Link {
    static ABSOLUTE_URI_PROTOCOLS = ['http://', 'https://'];
    static create(value: string) {
        const absoluteValue = this.ABSOLUTE_URI_PROTOCOLS.some((schema) => value.startsWith(schema))
            ? value
            : `https://${value}`;
        const node = super.create(absoluteValue);
        return node;
    }
}
Quill.register(AbsoluteLink, true);

function RichTextEditor(
    { name, value, className, hasError, onChange, onFocus, onBlur, readOnly }: RichTextEditorProps,
    ref: ForwardedRef<ReactQuill>
) {
    const changeHandler = (value: string) => {
        if (onChange && !readOnly) {
            onChange(name, value);
        }
    };

    const focusHandler = () => {
        if (onFocus && !readOnly) {
            onFocus(name);
        }
    };

    const blurHandler = () => {
        if (onBlur && !readOnly) {
            onBlur();
        }
    };

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ color: [] }, { background: [] }],
            ['link'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ],
    };

    return (
        <div>
            <ReactQuill
                ref={ref}
                modules={modules}
                className={`border transition-colors ${className ?? ''} ${
                    hasError ? FormStyles.InputError : 'rounded-sm border-primary-50 bg-purewhite'
                }`}
                value={value ?? ''}
                readOnly={readOnly}
                onChange={changeHandler}
                onFocus={focusHandler}
                onBlur={blurHandler}
                theme="snow"
            />
        </div>
    );
}

export default React.forwardRef(RichTextEditor);
