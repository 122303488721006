import { MediaImage } from '../../common/components/MediaImage';
import * as MediaUrls from '../../common/mediaApi/MediaUrlBuilder';
import { buttonStyleClass } from '../../common/ButtonNew';

export type Props = {
    title: string;
    description: string;
    linkTitle: string;
    linkUrl: string;
    tileMediaId: string;
    asSingle?: boolean;
};

export default function TileCard({ title, description, linkTitle, linkUrl, tileMediaId, asSingle = false }: Props) {
    const imageDimensionsClass = asSingle ? 'lg:flex-[0_0_444px] lg:w-[444px]' : 'lg:flex-[0_0_200px] lg:w-[200px]';

    const paddingClass = asSingle ? 'p-8 mx-14' : 'p-8';

    return (
        <div className="flex w-full flex-col overflow-hidden rounded-sm bg-white lg:h-[220px] lg:flex-row">
            {tileMediaId && (
                <MediaImage
                    key={tileMediaId}
                    className={`object-cover max-lg:h-[171px] lg:h-[220px] ${imageDimensionsClass}`}
                    alt="Tile image"
                    urlFunc={() => MediaUrls.globalTileDownload(tileMediaId)}
                />
            )}
            <div className={`flex h-full flex-col justify-center ${paddingClass} lg:h-[220px]`}>
                <div className="flex flex-[1_1_auto] flex-col justify-between">
                    <div className="mb-3 flex flex-col gap-2 max-lg:max-h-[142px] lg:max-h-[140px]">
                        <h3 className="line-clamp-2 flex-[0_0_auto]">{title}</h3>
                        <div
                            className="flex-[1_1_auto] overflow-y-auto text-sm font-normal leading-5"
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </div>
                    <div>
                        {linkUrl && linkTitle && (
                            <a
                                href={linkUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={buttonStyleClass({ buttonStyle: 'secondary', buttonSize: 'sm' })}
                            >
                                {linkTitle}
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
