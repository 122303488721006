import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { MediaImage } from './MediaImage';
import * as MediaUrls from '../mediaApi/MediaUrlBuilder';
import { formatDateToDDMMYYYY } from '../dateUtils';

export type Props = {
    cardTitle: string;
    title: string;
    description: string;
    linkTitle: string;
    linkUrl: string;
    tileMediaId: string;
};

export default function PartnershipEventMarketingTile({
    cardTitle,
    title,
    description,
    linkTitle,
    linkUrl,
    tileMediaId,
}: Props) {
    return (
        <div className="flex h-[340px] w-[640px] min-w-[640px] rounded bg-white p-6 shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.10),0px_1px_3px_0px_rgba(0,0,0,0.10)]">
            {tileMediaId && (
                <MediaImage
                    key={tileMediaId}
                    height={293}
                    width={285}
                    className="min-w-[285px] rounded object-cover"
                    alt="Event card image"
                    urlFunc={() => MediaUrls.globalTileDownload(tileMediaId)}
                />
            )}
            <div className="pb-5 pl-5 pt-4">
                <div className="mb-2 text-sm font-bold text-primary-500">
                    {cardTitle ? formatDateToDDMMYYYY(cardTitle) : ''}
                </div>
                <h3 className="mb-3 text-lg font-bold">{title}</h3>
                <div
                    className="mb-6 h-[142px] overflow-y-auto text-sm font-medium"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                <a href={linkUrl} target="_blank" rel="noopener noreferrer" className="flex">
                    <div className="mr-2 text-sm font-semibold uppercase text-primary-500">{linkTitle}</div>
                    {linkTitle && <ArrowLongRightIcon className="h-5 w-5 text-primary-500" />}
                </a>
            </div>
        </div>
    );
}
